(function(){
	var mmenu = require('jquery.mmenu');
	$('#mobile-nav').mmenu({
		slidingSubmenus: false,
		offCanvas: {
			position: 'right',
			zposition: 'front'
		},
		navbars: [{
			position: top,
			content: '<a class="close-toggle" href="#mm-0"><span></span><span></span></a>'
		}]
	});
	var API = $("#mobile-nav").data( "mmenu" );

		$(".close-toggle").click(function() {
			 API.close();
		});
})();
