( function() {
  var formContact = $( '#main-contact-form' );
  if ( formContact.length ) {
    formContact.submit( function( e ) {
      e.preventDefault();
      var targetUrl = Routing.generate( 'site_frontend_ajax_call' );
			var form_status = $( '.form_status' );

      $.ajax( {
        method: 'POST',
        url: targetUrl,
				beforeSend: function() {
					form_status.html( '<p><i class="fa fa-spinner fa-spin"></i> <b>Bericht verzenden...</b></p>' ).fadeIn();
				},
        data: {
          form: formContact.serializeArray()
        }
      } ).done( function( data ) {
        if ( data.status === "ok" ) {
					//form_status.html( '<p class="text-success">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          form_status.html( '<p class="text-success"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
          //	formContact.hide();
          formContact.trigger( 'reset' );
        }
        if ( data.status === "error" ) {
					//form_status.html( '<p class="text-error">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          form_status.html( '<p class="text-error"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
        }
      } );
    } );
  }
} )();

( function() {
  var formBook = $( '#book-form' );
  var bookDate = $( '.js-book-cal' );

  bookDate.datepicker( {
    dateFormat: 'dd/mm/yy'
  } ).on( 'changeDate', function( e ) {
    //console.log('changedate');



    var selectedDate = bookDate.datepicker( 'getDate' );
    // var currentDate = bookDate.datepicker.formatDate('dd, MM, yy', selectedDate);
    console.log( selectedDate );
    $( '.js-book-date' ).val( selectedDate );
  } );

  if ( formBook.length ) {
    formBook.submit( function( e ) {
      e.preventDefault();
      var targetUrl = Routing.generate( 'site_frontend_ajax_call' );
      var form_status = $( '.form_status' );
      $.ajax( {
        method: 'POST',
        url: targetUrl,
        beforeSend: function() {
          form_status.html( '<p><i class="fa fa-spinner fa-spin"></i> <b>Bericht verzenden...</b></p>' ).fadeIn();
        },
        data: {
          form: formBook.serializeArray()
        }
      } ).done( function( data ) {
        if ( data.status === "ok" ) {
          //form_status.html( '<p class="text-success">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          form_status.html( '<p class="text-success"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
          formBook.trigger( 'reset' );
        }
        if ( data.status === "error" ) {
          //form_status.html( '<p class="text-error">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          form_status.html( '<p class="text-error"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
        }
      } );
    } );
  }
} )();

( function() {
  var formCoupon = $( '#coupon-form' );
  if ( formCoupon.length ) {
    formCoupon.submit( function( e ) {
      e.preventDefault();
      var targetUrl = Routing.generate( 'site_frontend_ajax_call' );
      var form_status = $( '.form_status' );
      $.ajax( {
        method: 'POST',
        url: targetUrl,
        beforeSend: function() {
          //formCoupon.append( form_status.html( '<p><i class="fa fa-spinner fa-spin"></i> Bericht verzenden...</p>' ).fadeIn() );
          form_status.html( '<p><i class="fa fa-spinner fa-spin"></i> <b>Bericht verzenden...</b></p>' ).fadeIn();
        },
        data: {
          form: formCoupon.serializeArray()
        }
      } ).done( function( data ) {
        if ( data.status === "ok" ) {
          //form_status.html( '<p class="text-success">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          form_status.html( '<p class="text-success"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
          //formContact.hide();
          formCoupon.trigger( 'reset' );
        }
        if ( data.status === "error" ) {
          //form_status.html( '<p class="text-error">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          form_status.html( '<p class="text-error"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
        }
      } );
    } );
  }
} )();
