var slick = require("slick-carousel");

$(".js-hero__slider").slick({
  autoplay: true,
  fade: true,
  nextArrow: '<div class="hero__arrow hero__arrow--next"></div>',
  prevArrow: '<div class="hero__arrow hero__arrow--prev"></div>'
});

var $carousel = $(".js-gallery");

$carousel.slick({
  fade: true,
  nextArrow: '<div class="subpage__arrow subpage__arrow--next"></div>',
  prevArrow: '<div class="subpage__arrow subpage__arrow--prev"></div>'
});

$carousel.each(function() {
  $(this).magnificPopup({
    delegate: "img",
    type: "image",
    gallery: {
      enabled: true
    },
    callbacks: {
      elementParse: function(qw) {
        qw.src = qw.el.attr("src");
      }
    }
  });
});

$(".js-room-gallery").slick({
  autoplay: false,
  fade: true,
  nextArrow: '<div class="subpage__arrow subpage__arrow--next"></div>',
  prevArrow: '<div class="subpage__arrow subpage__arrow--prev"></div>',
  rows: 0
});

//
// $('.js-mobile-slider').slick({
//     nextArrow: '<div class="mobile__arrow mobile__arrow--next"></div>',
//     prevArrow: '<div class="mobile__arrow mobile__arrow--prev"></div>',
//     mobileFirst: true,
//     responsive: [{
//         breakpoint: 992,
//         settings: 'unslick'
//     }]
// });

// $('.room__gallery').each(function() {
//   $(this).magnificPopup({
//     type: 'image',
//     delegate: 'img',
//     gallery:
//     {
//       enabled: true
//     }
//   });
// });
